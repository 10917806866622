<template>
  <div class="record">
    <!-- 导航栏 修改支付密码-->
    <van-nav-bar :title="$t('blindBox.withdrawalProfile')" class="record_nav"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'">
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"></i>
      <template #title>
        <span :class="!$isLinkGame ? 'yellow-color' : 'white-color'">{{ $t('blindBox.withdrawalProfile') }}</span>
      </template>
    </van-nav-bar>

    <van-tabs v-model="active" swipeable line-width="15px">
      <!-- 全部记录 -->
      <van-tab :title="$t('recharge.selectItem')">
        <div v-if="lists.length > 0">
          <van-cell v-for="item in lists" :key="item.binkid">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{
      item.bankinfo.name
    }}</span>
                <span v-if="item.status === '已汇款'" class="list-item-title-sub">（{{ item.status === '已汇款' ?
      $t('withdraw.sent') : ''
                  }}）</span>
                <span v-else-if="item.status === '审核中'" class="list-item-title-sub-green">（{{
      item.status === '审核中' ? $t('withdraw.underConsideration') : ''
    }}）</span>
                <span v-else class="list-item-title-sub-green">（{{ item.status === '未通过' ? $t('recharge.failure') : ''
                  }}）</span>
              </div>
              <div class="list-item-indate-orange" v-if="item.status === '未通过'">
                {{ $t('withdraw.tutorReason') }}
              </div>
              <div class="list-item-indate">
                {{ item.bankinfo.bank }} {{ $t('withdraw.lastNumberMumber') }}
                {{ item.bankinfo.card.substr(item.bankinfo.card.length - 4) }}
              </div>
              <span class="list-item-indate">{{
      (item.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
    }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('withdraw.orderID') }} ：{{ item.id }}</div>
                <span class="list-item-money">{{ $money(item.money) }}</span>
              </div>
            </template>
          </van-cell>
        </div>

        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('withdraw.currentlyWithdrawalHistory') }}</div>
      </van-tab>
      <!-- 审核中 -->
      <van-tab :title="$t('withdraw.underReview')">
        <div v-if="reviewList.length > 0">
          <van-cell v-for=" item  in  reviewList " :key="item.binkid">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{
      item.bankinfo.name
    }}</span>

                <span class="list-item-title-sub-green">（{{
        item.status === '审核中' ? $t('withdraw.underConsideration') : ''
      }}）</span>
              </div>
              <div class="list-item-indate-orange" v-if="item.status === '未通过'">
                {{ $t('withdraw.tutorReason') }}
              </div>
              <div class="list-item-indate">
                {{ item.bankinfo.bank }} {{ $t('withdraw.lastNumberMumber') }}
                {{ item.bankinfo.card.substr(item.bankinfo.card.length - 4) }}
              </div>
              <span class="list-item-indate">{{
      (item.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
    }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('withdraw.orderID') }} ：{{ item.id }}</div>
                <span class="list-item-money">{{ $money(item.money) }}</span>
              </div>
            </template>
          </van-cell>
        </div>

        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('withdraw.currentlyWithdrawalHistory') }}</div>
      </van-tab>
      <!-- 已汇款 -->
      <van-tab :title="$t('recharge.success')">
        <div v-if="amountList.length > 0">
          <van-cell v-for=" item  in  amountList " :key="item.binkid">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{
      item.bankinfo.name
    }}</span>
                <span class="list-item-title-sub">（{{ item.status === '已汇款' ? $t('withdraw.sent') : '' }}）</span>
              </div>
              <div class="list-item-indate-orange" v-if="item.status === '未通过'">
                {{ $t('withdraw.tutorReason') }}
              </div>
              <div class="list-item-indate">
                {{ item.bankinfo.bank }} {{ $t('withdraw.lastNumberMumber') }}
                {{ item.bankinfo.card.substr(item.bankinfo.card.length - 4) }}
              </div>
              <span class="list-item-indate">{{
      (item.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
    }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('withdraw.orderID') }} ：{{ item.id }}</div>
                <span class="list-item-money">{{ $money(item.money) }}</span>
              </div>
            </template>
          </van-cell>
        </div>

        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('withdraw.currentlyWithdrawalHistory') }}</div>
      </van-tab>
      <!-- 未通过 -->
      <van-tab :title="$t('recharge.failure')">
        <div v-if="failList.length > 0">
          <van-cell v-for=" item  in  failList " :key="item.binkid">
            <template #title>
              <div class="list-item-title">
                <span class="list-item-title-text">{{
      item.bankinfo.name
    }}</span>

                <span class="list-item-title-sub-green">（{{ item.status === '未通过' ? $t('recharge.failure') : ''
                  }}）</span>
              </div>
              <div class="list-item-indate-orange" v-if="item.status === '未通过'">
                {{ $t('withdraw.tutorReason') }}
              </div>
              <div class="list-item-indate">
                {{ item.bankinfo.bank }} {{ $t('withdraw.lastNumberMumber') }}
                {{ item.bankinfo.card.substr(item.bankinfo.card.length - 4) }}
              </div>
              <span class="list-item-indate">{{
      (item.indate * 1000) | formatTime('YYYY-MM-DD HH:mm:ss')
    }}</span>
            </template>

            <template #default>
              <div class="list-item-right">
                <div class="list-item-order">{{ $t('withdraw.orderID') }} ：{{ item.id }}</div>
                <span class="list-item-money">{{ $money(item.money) }}</span>
              </div>
            </template>
          </van-cell>
        </div>

        <!-- language-ch 没有任何记录 -->
        <div v-else class="nolist">{{ $t('withdraw.currentlyWithdrawalHistory') }}</div>
      </van-tab>
    </van-tabs>

    <div class="list">{{ $t('common.noFurtherInformation') }}</div>
  </div>
</template>

<script>
import request from '@/utils/request.js'
export default {
  name: '',
  components: {},
  data() {
    return {
      active: 0,
      lists: [],
      listsNo: false,
      reviewList: [],
      amountList: [],
      failList: [],
    }
  },
  mounted() { },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      const {
        data: { data, ret },
      } = await request({
        method: 'get',
        url: 'users/extract/record',
        params: {
          take: 20,
        },
      })

      if (ret === 1) {
        this.lists = data.lists
      }
      this.reviewList = this.lists.filter((t) => t.status === '审核中')
      this.amountList = this.lists.filter((t) => t.status === '已汇款')
      this.failList = this.lists.filter((t) => t.status === '未通过')
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-tab__text--ellipsis {
  -webkit-line-clamp: 2;
}

.record {
  font-family: 'PingFang-Regular';
  padding-top: 92px;

  .record_nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    /deep/ .van-nav-bar__content {
      // background-color: #ff0000;
    }

    /deep/ .van-nav-bar__title {
      font-family: 'PingFang-Regular';
      // color: #fff;
    }

    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }

  .van-cell {
    box-sizing: border-box;
    padding: 20px 30px;

    /deep/ .van-cell__title {
      flex: none;
      width: 260px;

      .list-item-title {
        width: 240px;

        .list-item-title-text {
          font-size: 26px;
          color: #242424;
        }

        .list-item-title-sub,
        .list-item-title-sub-green {
          font-size: 24px;
          color: #dc1323;
        }

        .list-item-title-sub-green {
          color: #1bb019;
        }
      }

      .list-item-indate {
        // margin-top: 12px;
        font-size: 24px;
        color: #888888;
      }

      .list-item-indate-orange {
        color: orange;
        font-size: 24px;
      }
    }

    /deep/ .van-cell__value {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .list-item-right {
        .list-item-order {
          font-size: 16px !important;
          color: #999999;
        }

        .list-item-money {
          font-size: 34px;
          color: #dc1323;
        }
      }
    }
  }

  .nolist {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
    font-size: 24px;
    color: #999999;
  }

  .list {
    margin: 50px 0;
    text-align: center;
    font-size: 28px;
  }
}
</style>
